import { NgFor, NgIf } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { mediaUrl } from '@features/shared/data';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AdsIntegration } from 'src/app/core/domain/dukan-shop/dukan-shop.model';
import { UpdateDukanAdsIntegrationUseCase } from 'src/app/core/usecases/dukan-shop/update-dukan-ads-integration.usecase';
import { appUrlsConstantsInjectionToken } from 'src/app/data/injection-tokens/app-urls-constants.injection-token';

export interface IntegrationModel {
  type: 'gtm' | 'tiktok';
  name: string;
  title: string;
  icon: string;
  alt: string;
  controlName: string;
  placeholder: string;
  integrationData: string[];
}

@Component({
  selector: 'app-dukan-integrations',
  standalone: true,
  imports: [TranslateModule, NgFor, NgIf, FormsModule, ReactiveFormsModule],
  templateUrl: './dukan-integrations.component.html',
  styleUrls: ['./dukan-integrations.component.scss'],
})
export class DukanIntegrationsComponent implements OnInit {
  public tikTokImg = mediaUrl('learning/tiktok.svg');

  public assetsPath = 'assets/img/dukan/';

  public integrationForm: FormGroup;

  @Input() integrationIds: AdsIntegration;

  private _updateDukanAdsIntegrationUseCase = inject(UpdateDukanAdsIntegrationUseCase);

  private _toast = inject(ToastrService);

  private _translateService = inject(TranslateService);

  private _router = inject(Router);

  private _appURLs = inject(appUrlsConstantsInjectionToken);

  public integrationList: IntegrationModel[];

  ngOnInit(): void {
    this.integrationForm = new FormGroup({
      ttinput: new FormControl(''),
      gtminput: new FormControl(''),
    });
    const gtmIds: any = [];
    const pixelIds: any = [];
    this.integrationIds.googleTagCode?.ids.forEach((id) => gtmIds.push(id));
    this.integrationIds.tiktokPixel?.ids.forEach((id) => pixelIds.push(id));
    this.integrationList = [
      {
        title: 'Google Tag Manager',
        icon: `${this.assetsPath}gtm-icon.svg`,
        alt: 'GTM-Icon',
        name: 'Google Tag Manager Code / Google Tag',
        type: 'gtm',
        controlName: 'gtminput',
        placeholder: 'Google Tag Manager Code',
        integrationData: gtmIds,
      },
      {
        title: 'Tiktok Pixel',
        icon: this.tikTokImg,
        alt: 'tiktok-icon',
        name: 'Tiktok Pixel ID',
        type: 'tiktok',
        controlName: 'ttinput',
        placeholder: 'Tiktok Pixel ID',
        integrationData: pixelIds,
      },
    ];
  }

  addInput(control: any, type: any): void {
    const inputValue = this.integrationForm.get(control)!.value;
    this.integrationList.find((x) => x.type === type)!.integrationData.push(inputValue);
    this.integrationForm.get(control)!.reset();
  }

  removeInput(index: number, type: any): void {
    this.integrationList.find((x) => x.type === type)!.integrationData.splice(index, 1);
  }

  updatePixelIntegration(): void {
    const pixelData: AdsIntegration = {
      tiktokPixel: {
        ids: this.integrationList.find((x) => x.type === 'tiktok')!.integrationData,
      },
      googleTagCode: {
        ids: this.integrationList.find((x) => x.type === 'gtm')!.integrationData,
      },
    };
    this._updateDukanAdsIntegrationUseCase.execute(pixelData).subscribe({
      next: () => {
        this._toast.success(
          this._translateService.instant('STORES.DUKAN.DUKAN_EDIT_YOUR_STORE.SUCCESS'),
        );
        this._router.navigate([this._appURLs.STORES_URL]);
      },
      error: () => {},
    });
  }
}
